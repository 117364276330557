export const timeTravel = {
  order: 1,
  name: "Time travel",
  subcategory: "Time travel",
  markdown: `# Time travel

  Most views in Tower Hunt are displaying {% inlineRouterLink %}datapoints{% /inlineRouterLink %} as of a specific moment in time. Tower Hunt defaults to the current date, but you can use the {% inlineRouterLink articleId="timeline" %}timeline{% /inlineRouterLink %} to select other dates.

  ## Select a new date

  The currently selected date appears in orange. Follow the steps below to select a new date.

  {% tablessContainer %}
  1. Point to a position on the date range to see the corresponding date.
  2. Click the position to time travel to this date.
  3. If the selected date is different from the current date, you can quickly {% inlineAppIcon iconName="calendar" %}**view as of today**{% /inlineAppIcon %} using the button at the left of the timeline.
  {% /tablessContainer %}

  ## Adjust the timeline bounds

  The minimum and maximum dates appear at the left and right ends of the range, respectively. Adjusting either date is easy:

  {% tablessContainer %}
  1. Click on a date.
  2. Type a new date or type {% inlineKeyboardShortcut %}Space{% /inlineKeyboardShortcut %} to open a date picker.
  3. Click {% inlineAppIcon iconName="check" %}**set the date**{% /inlineAppIcon %}.
  {% /tablessContainer %}`,
};
